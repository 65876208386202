import {
  Button,
  Card,
  DatePicker,
  Dropdown,
  Form,
  Input,
  InputNumber,
  List,
  Menu,
  Modal,
  Popconfirm,
  Popover,
  Progress,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Table, Tag,
  Typography,
  Upload,
  message
} from 'antd';


import { DownOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import styled from "@emotion/styled";
import { ColumnsType } from "antd/lib/table";
import axios from "axios";
import moment from 'moment';
import { useEffect, useState } from "react";
import {
  useHistory
} from "react-router-dom";
import short from 'short-uuid';
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { Classification, Dataset } from "../../states/reducers/datasets";
import {  offlineUrlExpress } from "../../urls";
//import { fullFunction, angleFunction } from "../../urls/index";
import DatasetTableClassifications from "../Dataset-Table-Classifications";

//import { HighlightOutlined, SmileOutlined, SmileFilled } from '@ant-design/icons';

//import IframeResizer from 'iframe-resizer-react'
//import { truncateSync } from 'fs';

import testinfo from "../../assest/test_info.png";
import traininfo from "../../assest/train_info.png";
import validinfo from "../../assest/valid_info.png";


import { PolygonLabelActionType } from '../../contexts/polygonLabelAction';
import { usePolygonContext } from '../../contexts/polygonLabelContext';
import DatasetImageCrop from '../Dataset-Image-Crop';
import PolygonMainDrawer from '../Polygon-Main-Drawer';

const { TextArea } = Input;

const { Title, Text, Link } = Typography;

const MyTag = styled(Tag)`
  margin-bottom: 2px;
  :hover {
    color: darkblue;
  }
`;

const testTrain = () => {
  console.log("press train");
  //return <Redirect to='home/tasks'/>;
}

const columns = (
  username: string,
  autorunFunction: boolean,
  setDatasetIdForInserting: (id: number) => any,
  deleteDatasetByIdAsync: (id: number) => any,
  isDeletePending: boolean,
  setOnGoingLabelDataset: (
    datasetId: number,
    datasetType: string,
    name: string,
    classifications: Classification[],
    imagesCount: number,
  ) => any,
  showModal: (irecord: any, type: number) => any,
  handleEditDatasetName: (irecord: any) => any,
  handleCopyDataset: (irecord: any) => any,
  handleMergeDataset: (irecord: any) => any,
  handleBatchDelete: (irecord: any) => any,
  handleImportFolderLimit: (irecord: any) => any,
  handleEditDatasetCropImage: (irecord: any) => any,
  handleExportDataset: (irecord: any) => any,
  uploadImage_Props: (irecord: any) => any,
  uploadImageZip_Props: (irecord: any) => any,
  setOpenPolygonModal: (
    datasetId: number,
    datasetType: string,
    name: string,
    classifications: Classification[],
    imagesCount: number,) => any
): ColumnsType<Dataset> => [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      sorter: (a: any, b: any) => {
        a = a.name || '';
        b = b.name || '';
        return a.localeCompare(b);
      },
      /*
      render: (name: String) => {
        console.log(name);
        return (
          <>
          <Text editable={{
            //onChange: setCustomIconStr,
          }}>{name}</Text>
          </>
        );      
      }
      */
    },
    {
      key: "type",
      title: "Type",
      dataIndex: "type",
      sorter: (a: any, b: any) => {
        a = a.type || '';
        b = b.type || '';
        return a.localeCompare(b);
      },
      render: (type: string) => {
        return type.replace("_AND_", "&");
      },
    },
    {
      title: "Classifications",
      key: "classifications",
      render: (record: Dataset) => {
        const { classifications, id, type } = record;
        return (
          <DatasetTableClassifications
            datasetId={id}
            datasetType={type}
            classifications={classifications}
          />
        );
      },
    },
    {
      key: "user",
      title: "Create By",
      dataIndex: "user",
      sorter: (a: any, b: any) => {
        a = a.create_by || '';
        b = b.create_by || '';
        return a.localeCompare(b);
      },
    },
    {
      key: "create_at",
      title: "Create At",
      dataIndex: "create_at",
      sorter: (a: any, b: any) => {
        a = a.create_at || '';
        b = b.create_at || '';
        return a.localeCompare(b);
      },
      render: (create_at: string) => {
        let sdate = moment(create_at).format("YYYY/MM/DD HH:mm:ss");
        return (
          <div>{sdate}</div>
        );
      },
    },
    {
      key: "lastupdate",
      title: "Last Update Time",
      dataIndex: "lastupdate",
      sorter: (a: any, b: any) => {
        a = a.lastupdate || '';
        b = b.lastupdate || '';
        return a.localeCompare(b);
      },
      render: (lastupdate: string) => {
        let sdate = moment(lastupdate).format("YYYY/MM/DD HH:mm:ss");
        return (
          <div>{sdate}</div>
        );
      },
    },
    {
      key: "label/images",
      title: "Labeled Images",
      render: (record: Dataset) => {
        return (
          <div style={{ textAlign: "center" }}>
            {record.hasLabeled}/{record.imagesCount}
          </div>
        );
      },
    },
    {
      key: "management",
      title: "Management",
      render: (record: Dataset) => {
        const { user, id, classifications, name, type, imagesCount } = record;
        return (
          <>
            {record.type === 'SEGMENTATION' || record.type === 'KEYPOINT'  ? (
              <Tag
                onClick={() => setOpenPolygonModal(id, type, name, classifications, imagesCount)}
                color="geekblue"
              >
                Go Label
              </Tag>) : (
              <Tag
                onClick={
                  () => {
                    setOnGoingLabelDataset(id, type, name, classifications, imagesCount);
                  }
                }
                color="geekblue"
              >
                Go label
              </Tag >)}

            {user === username && (
              <MyTag onClick={() => setDatasetIdForInserting(id)} color="magenta">
                Add Images
              </MyTag>
            )}
            {user === username && (
              <Popover content={<>
                <Upload {...uploadImage_Props(record)}>
                  <MyTag color="cyan">Single Image</MyTag>
                </Upload>
                <Upload {...uploadImageZip_Props(record)}>
                  <MyTag color="cyan">Image Zip File</MyTag>
                </Upload>
              </>}
                title="Upload Image" trigger="click">
                <MyTag color="cyan">Upload Image</MyTag>
              </Popover>
            )}

            {/*
            {user === username && (
              <Upload {...uploadImage_Props(record)}>
                <MyTag color="cyan">Upload Image</MyTag>
              </Upload>
            )}
            {user === username && (
              <Upload {...uploadImageZip_Props(record)}>
                <MyTag color="cyan">Upload Image Zip</MyTag>
              </Upload>
            )} 
            */}
            {user === username && (
              <Popconfirm
                title="Danger! Process to Delete This Dataset!!"
                onConfirm={() => deleteDatasetByIdAsync(id)}
                okButtonProps={{ loading: isDeletePending }}
              >
                <MyTag color="volcano">Delete Dataset</MyTag>
              </Popconfirm>
            )}
            {user === "import" && (
              <Popconfirm
                title="Danger! Process to Delete This Dataset!!"
                onConfirm={() => deleteDatasetByIdAsync(id)}
                okButtonProps={{ loading: isDeletePending }}
              >
                <MyTag color="volcano">Delete Dataset</MyTag>
              </Popconfirm>
            )}
          </>
        );
      },
    },
    {
      key: "operation",
      title: "Operation",
      render: (record: Dataset) => {
        const { user, id, classifications, name, type } = record;

        function handleMenuClick(e: any) {
          if (e.key === 'rename') {
            handleEditDatasetName(record)
          } else if (e.key === 'duplicate') {
            handleCopyDataset(record)
          } else if (e.key === 'merge') {
            handleMergeDataset(record)
          } else if (e.key === 'batchdelete') {
            handleBatchDelete(record)
          } else if (e.key === 'importfolderlimit') {
            handleImportFolderLimit(record)
          } else if (e.key === 'datasetcropimage') {
            handleEditDatasetCropImage(record)
          } else if (e.key === 'export') {
            handleExportDataset(record)
          }
        }

        const menu = (
          <Menu onClick={handleMenuClick}>
            <Menu.Item key="rename" >
              Rename
            </Menu.Item>
            <Menu.Item key="duplicate" >
              Duplicate
            </Menu.Item>
            <Menu.Item key="merge" >
              Merge Dataset
            </Menu.Item>
            <Menu.Item key="batchdelete" >
              Batch Delete
            </Menu.Item>
            <Menu.Item key="datasetcropimage" >
              Dataset Crop Image
            </Menu.Item>
            <Menu.Item key="importfolderlimit" >
              Import Folder Limit
            </Menu.Item>
            <Menu.Item key="export" >
              Export
            </Menu.Item>
          </Menu>
        );
        return (
          <>
            <MyTag color="gold" onClick={() => showModal(record, 0)}>Train</MyTag>
            {record.type !== "ABNORMAL" &&
              <MyTag color="geekblue" onClick={() => showModal(record, 1)}>Validate</MyTag>
            }
            <MyTag color="magenta" onClick={() => showModal(record, 2)}>Test</MyTag>
            {(autorunFunction && record.type !== "ABNORMAL" && record.type !== "SEGMENTATION" && record.type !== "KEYPOINT") &&
            <MyTag color="purple" onClick={() => showModal(record, 3)}>AutoRun</MyTag>
            }

            {/*
          <MyTag color="green" onClick={(e) => { handleEditDatasetName(record) }} >Rename</MyTag>
          <MyTag color="purple" onClick={(e) => { handleCopyDataset(record) }} >Duplicate</MyTag>
          <MyTag color="volcano" onClick={(e) => { handleExportDataset(record) }} >Export</MyTag>
           */}
            <Dropdown overlay={menu}>
              <Tag color="green">Other <DownOutlined /></Tag>
            </Dropdown>

          </>
        );
      },
    },
  ];

export interface DatasetsTableProps {
  datasets: Dataset[];
  isLoading: boolean;
}

const DatasetsTable: React.FC<DatasetsTableProps> = ({
  datasets,
  isLoading,
}) => {
  const { currentUser, currentPermission } = useTypedSelector((state) => state.auth);

  const userPermission = JSON.parse(atob(currentPermission));

  const {autolabelFunction, autorunFunction, classifyFunction, segmentationFunction, keypointFunction, abnormalFunction, yoloFunction, angleFunction, smalldefectFunction, disgFunction} = userPermission;

  const { dispatchPolygon } = usePolygonContext();

  const {
    currentIndex,
    currentImage,
    isImageLoading,
    imagesCount,
    onGoingDatasetType,
    isDatasetImagesQueryCountPending,
    isOnGoingLabelModelOpen,
    isImageDeleting,
    currentLabelElements,
  } = useTypedSelector((state) => state.onGoinglabel);



  const { isDeletePending, isDeleteSuccess } = useTypedSelector(
    (state) => state.deleteDataset
  );
  const [isTrainModalVisible, setIsTrainModalVisible] = useState(false);
  const [isValidModalVisible, setIsValidModalVisible] = useState(false);
  const [isTestModalVisible, setIsTestModalVisible] = useState(false);
  const [isAutoRunModalVisible, setIsAutoRunModalVisible] = useState(false);

  const [isMappingClassVisible, setMappingClassVisible] = useState(false);
  const [getValidDatasetClass, setValidDatasetClass] = useState([]);
  const [getValidModalClass, setValidModalClass] = useState([]);

  const [isTypeClassify, setIsTypeClassify] = useState(false);

  const [getTrainRecord, setTrainRecord] = useState({});
  const [getValidRecord, setValidRecord] = useState({});
  const [getTestRecord, setTestRecord] = useState({});
  const [getAutoRunRecord, setAutoRunRecord] = useState({});

  const [getTrainAdvanceMode, setTrainAdvanceMode] = useState(false);

  const [getAutoRunExportNB, setAutoRunExportNB] = useState(false);
  const [getAutoRunTrainNM, setAutoRunTrainNM] = useState(false);

  const [angleTest, setAngleTest] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(false);

  const [retrainModel, setretrainModel] = useState(false);
  const [smallDefect, setSmallDefect] = useState(false);

  const [getModelsList, setModelsList] = useState([{ modelid: "", modelname: "", modelnumber: 0, classstring: "", score: 0 }]);
  const [getModelsListDefault, setModelsListDefault] = useState("");

  const [getSelectRecord, setSelectRecord] = useState({ id: 0, hasLabeled: 0, imagesCount: 0, name: "", type: "" });
  const [getSelectDatasetName, setSelectDatasetName] = useState("");

  const [isDatasetCropImageVisible, setIsDatasetCropImageVisible] = useState(false);

  const [isEditDatasetNameVisible, setIsEditDatasetNameVisible] = useState(false);

  const [isDuplicateDatasetVisible, setIsDuplicateDatasetVisible] = useState(false);

  const [isMergeDatasetVisible, setIsMergeDatasetVisible] = useState(false);

  const [isBatchDeleteVisible, setIsBatchDeleteVisible] = useState(false);

  const [isImportFolderLimitVisible, setIsImportFolderLimitVisible] = useState(false);

  const [IsChangeDataType, setIsChangeDataType] = useState(false);

  const [IsChangeClassificationFilter, setIsChangeClassificationFilter] = useState(false);

  const [getCopyDatasetClassifications, setCopyDatasetClassifications] = useState([]);

  const [getPreviewVisible, setPreviewVisible] = useState(false);
  const [getPreview, setPreview] = useState({});

  const [modal, contextHolder] = Modal.useModal();

  const [datasetForCrop, setDatasetForCrop] = useState<any>(undefined);

  const [datasetPosition, setDatasetPosition] = useState<any>();

  const [isExportting, setIsExporting] = useState(false);


  const [progress, setProgress] = useState(-1);

  const [processTaskid, setProcessTaskid] = useState(short.uuid());

  const [processing, setProcessing] = useState(0);

  const [processIntervalId, setProcessIntervalId] = useState(0);

  const [processStatus, setProcessStatus] = useState({ state: '', percent: 0, message: '' });

  const [searchText, setSearchText] = useState('');

  const controller = new AbortController();
  const cancelSource = axios.CancelToken.source()

  const upload_Props = {
    name: 'file',
    action: `${offlineUrlExpress}/api/importdataset/`,
    /*
    headers: {
      authorization: 'authorization-text',
    },
    */
    data: { taskid: processTaskid, username: currentUser },
    accept: 'zip,application/zip,application/x-zip,application/x-zip-compressed',
    showUploadList: false,
    onChange(info: any) {
      if (info.file.status === 'uploading') {
        setProcessStatus({ state: 'Uploading', percent: parseInt(info.file.percent), message: '' })
        setProgress(parseInt(info.file.percent));
      }
      //if (info.file.status !== 'uploading') {
      if (info.file.status === 'uploading' && parseInt(info.file.percent) > 99) {
        let suuid = short.uuid();
        console.log('suuid-->', suuid);
        setProcessTaskid(suuid);
        console.log(info.file, info.fileList);
        //setProgress(1);
        setProcessing(Math.random() * (65535 - 1) + 1);

        const newProcessIntervalId = window.setInterval(() => {
          axios.get(`${offlineUrlExpress}/tasks/${processTaskid}.txt`)
            .then(response => {
              setProcessStatus(response.data);
              console.log("gettaskstatus", response.data);
            })
        }, 1000);
        setProcessIntervalId(newProcessIntervalId);

      }
      if (info.file.status === 'done') {
        if (info.file.response.result === false) {
          message.error(`${info.file.name} file import failed, ${info.file.response.message}`, 5);
        } else {
          message.success(`${info.file.name} file import successfully`, 5);
        }
        setProgress(-1);
        clearInterval(processIntervalId);
        setProcessIntervalId(0);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`, 5);
        setProgress(-1);
        clearInterval(processIntervalId);
        setProcessIntervalId(0);
      }
    },
  };

  const uploadImage_Props = (record: any) => {
    return {
      name: 'file',
      action: `${offlineUrlExpress}/api/importdatasetimage/`,

      data: { taskid: processTaskid, datasetid: record.id, datasetname: record.name, username: currentUser },
      accept: 'image/*',
      showUploadList: false,
      onChange(info: any) {
        if (info.file.status !== 'uploading') {
          console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    }
  };

  const uploadImageZip_Props = (record: any) => {
    return {
      name: 'file',
      action: `${offlineUrlExpress}/api/importdatasetimagezip/`,
      /*
      headers: {
        authorization: 'authorization-text',
      },
      */
      data: { taskid: processTaskid, datasetid: record.id, datasetname: record.name, username: currentUser },
      accept: 'zip,application/zip,application/x-zip,application/x-zip-compressed',
      showUploadList: false,
      onChange(info: any) {
        if (info.file.status === 'uploading') {
          setProcessStatus({ state: 'Uploading', percent: parseInt(info.file.percent), message: '' })
          setProgress(parseInt(info.file.percent));
        }
        //if (info.file.status !== 'uploading') {
        if (info.file.status === 'uploading' && parseInt(info.file.percent) > 99) {
          let suuid = short.uuid();
          console.log('suuid-->', suuid);
          setProcessTaskid(suuid);
          console.log(info.file, info.fileList);
          //setProgress(1);
          setProcessing(Math.random() * (65535 - 1) + 1);

          const newProcessIntervalId = window.setInterval(() => {
            axios.get(`${offlineUrlExpress}/tasks/${processTaskid}.txt`)
              .then(response => {
                setProcessStatus(response.data);
                console.log("gettaskstatus", response.data);
              })
          }, 1000);
          setProcessIntervalId(newProcessIntervalId);

        }
        if (info.file.status === 'done') {
          if (info.file.response.result === false) {
            message.error(`${info.file.name} file import failed, ${info.file.response.message}`, 5);
          } else {
            message.success(`${info.file.name} file import successfully`, 5);
          }
          setProgress(-1);
          clearInterval(processIntervalId);
          setProcessIntervalId(0);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`, 5);
          setProgress(-1);
          clearInterval(processIntervalId);
          setProcessIntervalId(0);
        }
      },
    }
  };

  let history = useHistory();

  const redirect = (id: number) => {
    history.push('/home/train?dsid=' + id)
  }

  const handleExportDataset = async (sdata: any) => {
    setSelectRecord(sdata);
    setSelectDatasetName(sdata.name);

    //const hide = message.loading('Model Export...', 0);
    let suuid = short.uuid();
    console.log("suuid:", suuid);
    setProcessTaskid(suuid);
    setProgress(0);

    const intervalprocessing = window.setInterval(() => {
      axios.get(`${offlineUrlExpress}/tasks/${suuid}.txt`)
        .then(response => {
          setProcessStatus(response.data);
          console.log("gettaskstatus", response.data);
        })
    }, 1000);

    setIsExporting(true);
    axios({
      method: 'post',
      url: `${offlineUrlExpress}/api/exportdataset/${sdata.id}`,
      data: { taskid: suuid },
      responseType: 'blob',
      headers: {},
      cancelToken: cancelSource.token,
      onUploadProgress(progressEvent) {
        //setProcessing(Math.random() * (65535 - 1) + 1);
        console.log(progressEvent);

      },
      onDownloadProgress(progressEvent) {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setProcessStatus({ state: 'Downloading', percent: percentCompleted, message: `Dataset_${sdata['name']}.zip` });
        setProgress(percentCompleted);
        //setProcessing(Math.random() * (65535 - 1) + 1);
        //console.log(progressEvent);
      }
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', "Dataset_" + sdata['name'] + ".zip");
        document.body.appendChild(link);
        link.click();
        setIsExporting(false);
        //setTimeout(hide, 500);
        //setProgress(0);
        message.success(`${sdata.name} export successfully`);
        setProgress(-1);
        window.clearInterval(intervalprocessing);
      })
      .catch((error) => {
        alert(error);
        //setTimeout(hide, 500);
        setProgress(-1);
        window.clearInterval(intervalprocessing);
        setIsExporting(false);
      }).then(function () {
        setProgress(-1);
        window.clearInterval(intervalprocessing);
      });

    /*
    let res = await axios.get(`${offlineUrlExpress}/api/exportdataset/${sdata.id}`)
    if (res) {
      console.log(res.data);
      const fileData = JSON.stringify(res.data, null, "\t");
      const blob = new Blob([fileData], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = `export_dataset_${sdata.name}.json`;
      link.href = url;
      link.click();
    }
    */
  };

  const [formDuplicateDataset] = Form.useForm();

  const handleCopyDataset = (sdata: any) => {
    setSelectRecord(sdata);
    setSelectDatasetName(sdata.name);
    setCopyDatasetClassifications(sdata.classifications);
    setIsChangeClassificationFilter(false);
    console.log(sdata);
    formDuplicateDataset.setFieldsValue({
      datasetname: sdata['name'] + "_Copy",
      datasettype: sdata['type']
    })

    setIsTypeClassify(false);

    if (sdata['type'] === 'CLASSIFY' || sdata['type'] === 'ABNORMAL') {
      setIsTypeClassify(true);
    }

    setIsChangeDataType(false);
    setIsDuplicateDatasetVisible(true);
  };

  const handleDuplicateDatasetOk = () => {
    formDuplicateDataset
      .validateFields()
      .then(values => {

        console.log(values);

        let recordData: any = getSelectRecord;
        if (values['datasetname'] !== recordData['datasetname']) {

          let newdatasettype = null;
          if (values['changedatatype']) {
            newdatasettype = values['datasettype'];
          }

          if (values['changeclassificationfilter'] === false) {
            values['classificationfilter'] = [];
          }

          let postData = { datasetname: values['datasetname'], filter: values['filter'], classificationfilter: values['classificationfilter'], datasettype: newdatasettype, username: currentUser }
          console.log(postData);

          axios.post(`${offlineUrlExpress}/api/dataset-duplicate/${recordData['id']}`, JSON.stringify(postData), { cancelToken: cancelSource.token, headers: { 'Content-Type': 'application/json' } })
            .then(res => {
              message.success('Dataset Duplicate Success', 1);
              fetchDatasetsAsync();
            })

        }
        formDuplicateDataset.resetFields();
        setIsDuplicateDatasetVisible(false);
      })
      .catch(info => {
        console.log('Duplicate Dataset Failed:', info);
      });
  };

  const handleDuplicateDatasetCancel = () => {
    formDuplicateDataset.resetFields();
    setIsDuplicateDatasetVisible(false);
  };

  const handleDuplicateDatasetPreview = async () => {

    let recordData: any = getSelectRecord;
    let values: any = formDuplicateDataset.getFieldsValue();

    console.log(getSelectRecord);


    if (values['changeclassificationfilter'] === false) {
      values['classificationfilter'] = [];
    }

    console.log(values);


    let postData = { filter: values['filter'], classificationfilter: values['classificationfilter'] }
    console.log(postData);

    axios.post(`${offlineUrlExpress}/api/dataset-duplicate-preview-filename/${recordData['id']}`, JSON.stringify(postData), { headers: { 'Content-Type': 'application/json' } })
      .then(res => {
        let previewlist: any = res.data;
        let previewstring = '';
        for (let i = 0; i < previewlist.length; i++) {
          previewstring += previewlist[i]['filename'] + '\n';
          //if (i>20){
          //  previewstring += "\n......";
          //  break;
          //}
        }

        Modal.info({
          title: `Filter Preview (Total File Count : ${previewlist.length})`,
          content: (
            <><TextArea rows={20} value={previewstring}>

            </TextArea>
            </>
          ),
          width: 1000,
          onOk() { },
        });

      })

  };

  const [formMergeDataset] = Form.useForm();

  const handleMergeDataset = (sdata: any) => {
    setSelectRecord(sdata);
    setSelectDatasetName(sdata.name);
    //setCopyDatasetClassifications(sdata.classifications);
    setIsChangeClassificationFilter(false);
    console.log(sdata);
    formMergeDataset.setFieldsValue({
      datasetname: sdata['name'] + "_Merge",
      datasettype: sdata['type']
    })

    setIsTypeClassify(false);

    if (sdata['type'] === 'CLASSIFY' || sdata['type'] === 'ABNORMAL') {
      setIsTypeClassify(true);
    }

    setIsChangeDataType(false);
    setIsMergeDatasetVisible(true);
  };

  const handleMergeDatasetOk = () => {
    formMergeDataset
      .validateFields()
      .then(values => {

        console.log(values);

        let recordData: any = getSelectRecord;
        if (values['datasetname'] !== recordData['datasetname']) {

          let mergedatasetslist = [];

          mergedatasetslist.push(recordData['id']);
          mergedatasetslist.push(values['merge_dataset_id']);

          let newdatasettype = null;
          if (values['changedatatype']) {
            newdatasettype = values['datasettype'];
          }

          if (values['changeclassificationfilter'] === false) {
            values['classificationfilter'] = [];
          }

          let postData = { datasetname: values['datasetname'], mergedatasetslist: mergedatasetslist, username: currentUser }
          console.log(postData);

          axios.post(`${offlineUrlExpress}/api/dataset-merge`, JSON.stringify(postData), { cancelToken: cancelSource.token, headers: { 'Content-Type': 'application/json' } })
            .then(res => {
              message.success('Dataset Merge Success', 1);
              fetchDatasetsAsync();
            })

        }
        formMergeDataset.resetFields();
        setIsMergeDatasetVisible(false);
      })
      .catch(info => {
        console.log('Merge Dataset Failed:', info);
      });
  };

  const handleMergeDatasetCancel = () => {
    formMergeDataset.resetFields();
    setIsMergeDatasetVisible(false);
  };

  const handleMergeDatasetPreview = async () => {

    let recordData: any = getSelectRecord;
    let values: any = formMergeDataset.getFieldsValue();

    console.log(getSelectRecord);


    if (values['changeclassificationfilter'] === false) {
      values['classificationfilter'] = [];
    }

    console.log(values);


    let postData = { filter: values['filter'], classificationfilter: values['classificationfilter'] }
    console.log(postData);

    axios.post(`${offlineUrlExpress}/api/dataset-Merge-preview-filename/${recordData['id']}`, JSON.stringify(postData), { headers: { 'Content-Type': 'application/json' } })
      .then(res => {
        let previewlist: any = res.data;
        let previewstring = '';
        for (let i = 0; i < previewlist.length; i++) {
          previewstring += previewlist[i]['filename'] + '\n';
          //if (i>20){
          //  previewstring += "\n......";
          //  break;
          //}
        }

        Modal.info({
          title: `Filter Preview (Total File Count : ${previewlist.length})`,
          content: (
            <><TextArea rows={20} value={previewstring}>

            </TextArea>
            </>
          ),
          width: 1000,
          onOk() { },
        });

      })

  };


  const handleBatchDelete = (sdata: any) => {
    setSelectRecord(sdata);
    formBatchDelete.setFieldsValue({
      indexrange: `1-${sdata["imagesCount"]}`,
      //endrecord: sdata["imagesCount"]

    });
    setSelectDatasetName(sdata.name);
    setIsBatchDeleteVisible(true);
  };

  const [formBatchDelete] = Form.useForm();

  const handleBatchDeleteOk = () => {
    formBatchDelete
      .validateFields()
      .then(values => {

        //console.log(values);

        let recordData: any = getSelectRecord;

        let postData = {
          filter: values['filter'],
          indexrange: values['indexrange'],
          //startrecord: values['startrecord'],
          //endrecord: values['endrecord'],
          minDate: values['minDate'],
          maxDate: values['maxDate']
        }

        console.log(postData);

        axios.post(`${offlineUrlExpress}/api/batch-delete-dataset-filename/${recordData['id']}`, JSON.stringify(postData), { cancelToken: cancelSource.token, headers: { 'Content-Type': 'application/json' } })
          .then(res => {

            message.success('Success Batch Delete Datasets File', 1);
            fetchDatasetsAsync();

          })

        formBatchDelete.resetFields();
        setIsBatchDeleteVisible(false);
        toggleLabelingRefresh();
      })
      .catch(info => {
        console.log('Batch Delete Failed:', info);
      });
  };

  const handleBatchDeleteCancel = () => {
    formBatchDelete.resetFields();
    setIsBatchDeleteVisible(false);
  };

  const handleBatchDeletePreview = async () => {

    formBatchDelete
      .validateFields()
      .then(values => {

        let recordData: any = getSelectRecord;
        //let values: any = formBatchDelete.getFieldsValue();

        //console.log(getSelectRecord);
        //console.log(formBatchDelete.getFieldsValue());

        let postData = {
          filter: values['filter'],
          indexrange: values['indexrange'],
          //startrecord: values['startrecord'],
          //endrecord: values['endrecord'],
          minDate: values['minDate'],
          maxDate: values['maxDate']
        }

        console.log(postData);


        axios.post(`${offlineUrlExpress}/api/batch-delete-preview-filename/${recordData['id']}`, JSON.stringify(postData), { headers: { 'Content-Type': 'application/json' } })
          .then(res => {
            let previewlist: any = res.data;
            let previewstring = '';
            for (let i = 0; i < previewlist.length; i++) {
              previewstring += '[' + previewlist[i]['index'] + '] ' + previewlist[i]['filename'] + '\n';

              //if (i>20){
              //  previewstring += "\n......";
              //  break;
              //}
            }

            Modal.info({
              title: `Filter Preview (Total File Count : ${previewlist.length})`,
              content: (
                <><TextArea rows={20} value={previewstring}>

                </TextArea>
                </>
              ),
              width: 1000,
              onOk() { },
            });

          })

      })
      .catch(info => {
        console.log('Batch Delete Failed:', info);
      });

  };


  const handleImportFolderLimit = (sdata: any) => {
    setSelectRecord(sdata);
    formImportFolderLimit.setFieldsValue({
      importlimit: sdata["importLimit"]
    });
    setSelectDatasetName(sdata.name);
    setIsImportFolderLimitVisible(true);
  };

  const [formImportFolderLimit] = Form.useForm();

  const handleImportFolderLimitOk = () => {
    formImportFolderLimit
      .validateFields()
      .then(values => {

        console.log(values);

        let recordData: any = getSelectRecord;

        let postData = {
          importlimit: values['importlimit'],
        }

        console.log(postData);

        axios.put(`${offlineUrlExpress}/api/editdatasetimportlimit/${recordData['id']}`, JSON.stringify(postData), { headers: { 'Content-Type': 'application/json' } })
          .then(res => {

            message.success('Success Modify Import Folder Limit', 1);
            fetchDatasetsAsync();

          })

        formImportFolderLimit.resetFields();
        setIsImportFolderLimitVisible(false);
      })
      .catch(info => {
        console.log('Modify Import Folder Limit Failed:', info);
      });
  };

  const handleImportFolderLimitCancel = () => {
    formImportFolderLimit.resetFields();
    setIsImportFolderLimitVisible(false);
  };


  const onChangeDataType = (checked: any) => {
    setIsChangeDataType(checked);
  }

  const onChangeClassificationFilter = (checked: any) => {
    setIsChangeClassificationFilter(checked);
  }

  const onChangeClassificationFilterSelect = (value: string[]) => {
    console.log(`selected ${value}`);
  };

  const handleEditDatasetName = (sdata: any) => {
    setSelectRecord(sdata);
    setSelectDatasetName(sdata.name);
    formEditDatasetName.setFieldsValue({
      datasetname: sdata['name'],
    })
    setIsEditDatasetNameVisible(true);
  };

  const [formEditDatasetName] = Form.useForm();

  const handleEditDatasetNameDatasetNameOk = () => {
    formEditDatasetName
      .validateFields()
      .then(values => {
        let recordData: any = getSelectRecord;
        if (values['datasetname'] !== recordData['datasetname']) {
          console.log(recordData, values);
          let postData = { datasetname: values['datasetname'] }

          axios.put(`${offlineUrlExpress}/api/editdatasetname/${recordData['id']}`, JSON.stringify(postData), { headers: { 'Content-Type': 'application/json' } })
            .then(res => {
              message.success('Dataset Name Edit Success', 1);
              fetchDatasetsAsync();
            })
        }
        formEditDatasetName.resetFields();
        setIsEditDatasetNameVisible(false);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });

  };

  const handleEditDatasetNameDatasetNameCancel = () => {
    formEditDatasetName.resetFields();
    setIsEditDatasetNameVisible(false);
  };

  //async function checkDatasetStatus(irecord:any) : Promise<boolean> {
  const checkDatasetStatus = async (irecord: any): Promise<boolean> => {
    let res = await axios.get(`${offlineUrlExpress}/api/getdatasetclassstatus/${irecord.id}`)
    if (res.data.length > 0) {
      if (res.data.length == irecord.classifications.length) {
        return false;
      } else {
        let labelclasses = [];
        let nfclasses = [];

        for (let i = 0; i < res.data.length; i++) {
          labelclasses.push(res.data[i]['description'])
        }

        for (let i = 0; i < irecord.classifications.length; i++) {
          if (labelclasses.indexOf(irecord.classifications[i]['description']) == -1) {
            nfclasses.push(irecord.classifications[i]['description'])
          }
        }

        message.error(`[${nfclasses.join(',')}] class not yet label, at least label 1 image !!`);
        return true;
      }
    } else {
      let nfclasses = [];
      for (let i = 0; i < irecord.classifications.length; i++) {
        nfclasses.push(irecord.classifications[i]['description'])
      }

      message.error(`[${nfclasses.join(',')}] class not yet label, at least label 1 image !!`);
      return true;
    }
    console.log(res)
  }

  const showModal = async (irecord: any, type: number) => {
    console.log(irecord);

    axios.get(`${offlineUrlExpress}/api/getmodelslist/${irecord.type}`)
      .then(res => {
        //setModelsList(res.data)

        if (res.data.length > 0) {

          let filtermodelslist = [];

          for (let i = 0; i < res.data.length; i++) {
            res.data[i]['classnumber'] = Object.keys(res.data[i]['classes']).length
            res.data[i]['classstring'] = Object.keys(res.data[i]['classes']).join(',')
            res.data[i]['score'] = Math.round(res.data[i]['score'] * 100) / 100;

            if (irecord.type == "DETECT") {
              filtermodelslist.push(res.data[i]);
            } else {
              if (Object.keys(res.data[i]['classes']).length == irecord.classifications.length) {
                filtermodelslist.push(res.data[i]);
              }
            }
          }

          let filtersamemodelslist = [];

          let RecordClassifications = irecord["classifications"];
          let RecordClasses: { [k: string]: any } = {};
          let RecordClassesArray = [];
          for (let i = 0; i < RecordClassifications.length; i++) {
            RecordClasses[RecordClassifications[i].description] = RecordClassifications[i].cid;
            RecordClassesArray.push(RecordClassifications[i].description);
          }

          for (let i = 0; i < res.data.length; i++) {
            if (irecord.type == "DETECT") {
              filtersamemodelslist.push(res.data[i]);
            } else {
              let tempClassesArray = Object.keys(res.data[i]['classes']);
              console.log(JSON.stringify(tempClassesArray.sort()), JSON.stringify(RecordClassesArray.sort()));
              if (JSON.stringify(tempClassesArray.sort()) === JSON.stringify(RecordClassesArray.sort())) {
                filtersamemodelslist.push(res.data[i]);
              }
            }
          }

          //setModelsListDefault(res.data[0]['modelid']);
          if (filtermodelslist.length > 0) {
            setModelsListDefault(filtermodelslist[0]['modelid']);
          } else {
            setModelsListDefault("");
          }

          //console.log("----getModelsListDefault----")

          //console.log(res.data[0]['modelid']);
          //setModelsList(res.data);
          //setModelsList(filtermodelslist);
          setModelsList(filtersamemodelslist);
        } else {
          setModelsList(res.data);
          setModelsListDefault("");
        }
        console.log(res.data)
      })

    setIsTypeClassify(false);

    let checkDatasetsError: boolean = false;

    if ((irecord['type'] === "CLASSIFY" || irecord['type'] === "DETECT_AND_CLASSIFY" || irecord['type'] === "SEGMENTATION" || irecord['type'] === "KEYPOINT" || irecord['type'] === "ABNORMAL") && !(type == 2 || type == 3)) {
      checkDatasetsError = await checkDatasetStatus(irecord);
    }

    console.log(checkDatasetsError);

    if (checkDatasetsError == false) {
      if (type === 0) {

        setTrainAdvanceMode(false);


        /*
        label 0~200 -> epoch 50
        label 200~400 -> epoch 30
        label 400~1000 -> epoch 20
        label >1000 -> epoch 15
        */

        let iepochs = 20;

        if (irecord['hasLabeled'] < 200) {
          iepochs = 50;
        }

        if (irecord['hasLabeled'] > 200 && irecord['hasLabeled'] < 400) {
          iepochs = 30;
        }

        if (irecord['hasLabeled'] > 400 && irecord['hasLabeled'] < 1000) {
          iepochs = 20;
        }

        if (irecord['hasLabeled'] > 1000) {
          iepochs = 15;
        }


        console.log("irecord['hasLabeled']-->", irecord['hasLabeled'], iepochs)


        formTrain.resetFields();
        if (irecord['type'] === "CLASSIFY") {
          setIsTypeClassify(true);
          formTrain.setFieldsValue({
            model_backbone: 'resnest50',
            learning_rate: 0.0001,
            epochs: iepochs
          })
        } else {
          formTrain.setFieldsValue({
            model_backbone: 'resnet50',
            epochs: iepochs
          })
        }
        setTrainRecord(irecord);
        setSelectDatasetName(irecord.name);
        setIsTrainModalVisible(true);


      } else if (type === 1) {
        if (irecord['type'] === "CLASSIFY") {
          setIsTypeClassify(true);
        }
        setMappingClassVisible(false);
        formValid.resetFields();
        setValidRecord(irecord);
        setSelectDatasetName(irecord.name);
        /*
        formValid.setFieldsValue(
          load_model: getModelsListDefault
        });  
        */
        setIsValidModalVisible(true);
      } else if (type === 2) {
        if (irecord['type'] === "CLASSIFY") {
          setIsTypeClassify(true);
        }
        setTestRecord(irecord);
        setSelectDatasetName(irecord.name);

        formTest.setFieldsValue({
          endrecord: irecord["imagesCount"]
        });

        setIsTestModalVisible(true);
      } else if (type === 3) {
        if (irecord['type'] === "CLASSIFY") {
          setIsTypeClassify(true);
        }
        setAutoRunRecord(irecord);
        setSelectDatasetName(irecord.name);

        formAutoRun.setFieldsValue({
          endrecord: irecord["imagesCount"]
        });

        setIsAutoRunModalVisible(true);
      }
    }
  };


  const handleEditDatasetCropImage = (sdata: any) => {
    //console.log("handleEditDatasetCropImage",sdata);
    setDatasetForCrop({
      ...sdata
    });
    setSelectRecord(sdata);
    setSelectDatasetName(sdata.name);
    formDatasetCropImage.setFieldsValue({
      datasetname: sdata['name'],
    })
    setIsDatasetCropImageVisible(true);
  };

  const [formDatasetCropImage] = Form.useForm();

  const handleDatasetCropImageDatasetNameOk = () => {

    const dataset_id = datasetForCrop?.id;
    try {
      axios.put(`${offlineUrlExpress}/api/DatasetCropImage/${dataset_id}`,
        JSON.stringify(datasetPosition),
        {
          headers: { 'Content-Type': 'application/json' }
        })
        .then(res => {
          message.success("update successfully");
          fetchDatasetsAsync();
          setIsDatasetCropImageVisible(false);
        })
    } catch (err: any) {
      console.log('Validate Failed:', err);
      message.error(err)
    };

  };

  const handleDatasetCropImageDatasetNameCancel = () => {
    formDatasetCropImage.resetFields();
    setIsDatasetCropImageVisible(false);
  };

  const [formTrain] = Form.useForm();

  const handleTrainOk = () => {
    formTrain
      .validateFields()
      .then(values => {

        Object.keys(values).forEach(key => {
          if (typeof (values[key]) === 'boolean') {
            values[key] = values[key].toString();
          }
        });

        let postData = {
          "modelname": "model_name_20211212",
          "datasetname": "Detect Train",
          "classes": "{}",
          "type": "DETECT",
          "action": "train",
          "directory": "",
          "retrain_model": "false",
          "load_model_id": "",
          "username": "",
          "source": "",
          "cropRegion": { "topLeftX": 0, "topLeftY": 0, "bottomRightX": 1, "bottomRightY": 1 },
          "params": {
            "epochs": 20,
            "model_backbone": "resnet50",
            "use_pretrained": "true",
            "learning_rate": 0.001,
            "optimizer": "adam",
            "save_best_model": "false",
            "data_aug": "true",
            "image_size": 800,
            "batch_size": 2,
            "validation_size": 0.2,
            "load_model": "false"
          }
        };

        console.log('post values');
        console.log(values);

        let recordData: any = getTrainRecord;
        let id = recordData.id;
        postData.modelname = values.modelname;
        postData.datasetname = recordData.name;
        postData.type = recordData.type;
        postData.cropRegion = recordData.cropRegion;
        postData.source = values.source;


        if (recordData.type === "DETECT_AND_CLASSIFY" || recordData.type === "SEGMENTATION" || recordData.type === "KEYPOINT" || recordData.type === "CLASSIFY" || recordData.type === "ABNORMAL") {
          let classes: any = {}
          let classifications: any = recordData.classifications;
          for (let i = 0; i < classifications.length; i++) {
            classes[classifications[i]['description']] = classifications[i]['cid']
          }
          //postData.classes = classes;
          postData.classes = JSON.stringify(classes);
          console.log(classes)
        }

        if (recordData.type === "ABNORMAL") {
          postData.type = "ABNORMAL";
        }

        postData.action = 'train';
        if (values.retrain_model === "true") {
          values.load_model = "";
          postData.load_model_id = values.load_model_id;
          postData.retrain_model = "true";
        } else {
          values.load_model = "false";
        }
        delete values.modelname;
        delete values.retrain_model;
        delete values.load_model_id;
        delete values.advance_mode;

        postData.username = currentUser;

        postData.params = values;
        console.log(postData);


        axios.post(`${offlineUrlExpress}/api/createmodel/${id}`, JSON.stringify(postData), { headers: { 'Content-Type': 'application/json' } })
          .then(res => {
            if (res.data['result']) {
              history.push('/home/tasks')
            } else {
              message.error(res.data['message']);
            }
          })

        //message.info(`~~~~ Model Train Function was Disable in Demo Version ~~~~`, 10);

        setretrainModel(false);
        setSmallDefect(false);
        formTrain.resetFields();
        setIsTrainModalVisible(false);
        //onCreate(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });

    //setIsTrainModalVisible(false);
    //history.push('/home/tasks')

  };

  const handleTrainCancel = () => {
    setretrainModel(false);
    setSmallDefect(false);
    formTrain.resetFields();
    setIsTrainModalVisible(false);
  };

  const handleTrainReset = () => {
    setretrainModel(false);
    setSmallDefect(false);
    formTrain.resetFields();
  };

  const [formValid] = Form.useForm();

  const handleValidOk = () => {
    formValid
      .validateFields()
      .then(values => {

        let classmapping: any = [];

        Object.keys(values).forEach(key => {
          if (typeof (values[key]) === 'boolean') {
            values[key] = values[key].toString();
          }
          if (key.includes("class_")) {
            classmapping.push(values[key]);
            delete values[key];
          }
        });

        console.log(classmapping);

        let postData = {
          "modelname": "",
          "datasetname": "Detect Validation",
          "classes": "{}",
          "classmapping": "",
          "type": "DETECT",
          "action": "valid",
          "directory": "",
          "username": "",
          "cropRegion": { "topLeftX": 0, "topLeftY": 0, "bottomRightX": 1, "bottomRightY": 1 },
          "params": {
            "image_size": 800,
            "load_model": "Model/test.pth",
            "confidence": 0.5,
            "nms_threshold": 0.01
          }
        };

        let ModelsList: any = getModelsList;

        let modelName: string = "";
        let modelClasses: string = "";
        for (let i = 0; i < ModelsList.length; i++) {
          console.log(ModelsList[i].modelid);
          if (ModelsList[i].modelid === values.load_model) {
            modelName = ModelsList[i].modelname;
            modelClasses = JSON.stringify(ModelsList[i].classes);
            break;
          }
        }
        postData.modelname = modelName;
        //postData.classes = modelClasses;

        let recordData: any = getValidRecord;
        let id = recordData.id;

        if (recordData.type === "DETECT_AND_CLASSIFY" || recordData.type === "SEGMENTATION" || recordData.type === "KEYPOINT" || recordData.type === "CLASSIFY" || recordData.type === "ABNORMAL") {
          let classes: any = {}
          let classifications: any = recordData.classifications;
          for (let i = 0; i < classifications.length; i++) {
            classes[classifications[i]['description']] = classifications[i]['cid']
          }
          postData.classes = JSON.stringify(classes);
          postData.classmapping = JSON.stringify(classmapping);
          console.log(classes)
        }

        //disable nms_threshold on CLASSIFY
        if (recordData.type === "CLASSIFY") {
          delete values.nms_threshold
        }

        postData.username = currentUser;

        postData.params = values;
        postData.datasetname = recordData.name;
        postData.type = recordData.type;
        postData.cropRegion = recordData.cropRegion;
        postData.action = 'valid';
        console.log(postData);

        axios.post(`${offlineUrlExpress}/api/createmodel/${id}`, JSON.stringify(postData), { headers: { 'Content-Type': 'application/json' } })
          .then(res => {
            history.push('/home/tasks')
          })
        formValid.resetFields();
        setIsValidModalVisible(false);
        //onCreate(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });

    //setIsValidModalVisible(false);  
    //history.push('/home/tasks')

  };

  const handleValidCancel = () => {
    formValid.resetFields();
    setIsValidModalVisible(false);
  };

  const handleValidReset = () => {
    setMappingClassVisible(false);
    formValid.resetFields();
  };

  const [formTest] = Form.useForm();

  const handleTestOk = () => {
    formTest
      .validateFields()
      .then(values => {

        Object.keys(values).forEach(key => {
          if (typeof (values[key]) === 'boolean') {
            values[key] = values[key].toString();
          }
        });

        let postData = {
          "modelname": "",
          "datasetname": "Detect Validation",
          "datasetclasses": "{}",
          "classes": "{}",
          "type": "DETECT",
          "action": "test",
          "directory": "",
          "username": "",
          "cropRegion": { "topLeftX": 0, "topLeftY": 0, "bottomRightX": 1, "bottomRightY": 1 },
          "params": {
            "image_size": 800,
            "load_model": "Model/test.pth",
            "yaml_path": "Model/config.yaml",
            "confidence": 0.5,
            "nms_threshold": 0.01
          }
        };

        //element_split,aspect_ratio

        let ModelsList: any = getModelsList;

        let modelName: string = "";
        let modelClasses: string = "";

        for (let i = 0; i < ModelsList.length; i++) {
          console.log(ModelsList[i].modelid);
          if (ModelsList[i].modelid === values.load_model) {
            modelName = ModelsList[i].modelname;
            modelClasses = JSON.stringify(ModelsList[i].classes);
            break;
          }
        }
        postData.modelname = modelName;
        postData.classes = modelClasses;

        /*
        if (values['angle'] === 'false'){
          delete values['element_split'];
          delete values['angle'];
        }
        */

        if (values['aspect_ratio_check'] === 'false') {
          values['aspect_ratio'] = 'false';
        }
        delete values['aspect_ratio_check'];

        let recordData: any = getTestRecord;
        let id = recordData.id;

        /* Modify 20200208 Test Use Model Classes
        if (recordData.type === "DETECT_AND_CLASSIFY" || recordData.type === "CLASSIFY") {
          let classes: any = {}
          let classifications: any = recordData.classifications;
          for (let i = 0; i < classifications.length; i++) {
            classes[classifications[i]['description']] = classifications[i]['cid']
          }
          postData.classes = JSON.stringify(classes);
          console.log(classes);
        }
        */

        //disable nms_threshold on CLASSIFY
        if (recordData.type === "CLASSIFY") {
          delete values.nms_threshold
        }


        let classes_result: any = {};
        // Loop through each class in the input array
        recordData.classifications.forEach((classItem: any) => {
          // Extract id and description from the current class item
          const { cid, description } = classItem;

          // Map 'description' to its corresponding 'id'
          if (description) {
            classes_result[description] = cid;
          }
        });

        postData.datasetclasses = JSON.stringify(classes_result);



        postData.username = currentUser;

        postData.params = values;
        postData.datasetname = recordData.name;
        postData.type = recordData.type;
        postData.cropRegion = recordData.cropRegion;
        postData.action = 'test';

        if (recordData.type === "ABNORMAL") {
          postData.params.yaml_path = values.load_model.split("/").splice(-1) + "/config.yaml";
        }

        console.log(postData);

        axios.post(`${offlineUrlExpress}/api/testmodel/${id}`, JSON.stringify(postData), { headers: { 'Content-Type': 'application/json' } })
          .then(res => {
            history.push('/home/tasks')
          })
        formTest.resetFields();
        setIsTestModalVisible(false);
        //onCreate(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });

    //setIsTestModalVisible(false);
    //history.push('/home/tasks')

  };

  const handleTestCancel = () => {
    formTest.resetFields();
    setIsTestModalVisible(false);
  };

  const handleTestReset = () => {
    formTest.resetFields();
  };


  const [formAutoRun] = Form.useForm();

  const handleAutoRunOk = () => {
    formAutoRun
      .validateFields()
      .then(values => {

        Object.keys(values).forEach(key => {
          if (typeof (values[key]) === 'boolean') {
            values[key] = values[key].toString();
            if (key == "retrain") {
              if (values[key] == "false") {
                values[key] = 0;
              } else {
                values[key] = 1;
              }
            }
          }
        });

        let postData = {
          "modelname": "",
          "datasetname": "Detect Validation",
          "datasetclasses": "{}",
          "classes": "{}",
          "type": "DETECT",
          "action": "AutoRun",
          "directory": "",
          "username": "",
          "remark": "",
          "cropRegion": { "topLeftX": 0, "topLeftY": 0, "bottomRightX": 1, "bottomRightY": 1 },
          "params": {
            "image_size": 800,
            "load_model": "Model/AutoRun.pth",
            "model_backbone": "resnet50",
            "confidence": 0.5,
            "nms_threshold": 0.01,
            "epochs": 20,
            "validation_size": 0.2
          }
        };

        let ModelsList: any = getModelsList;

        let modelName: string = "";
        let modelClasses: string = "";

        for (let i = 0; i < ModelsList.length; i++) {
          console.log(ModelsList[i].modelid);
          if (ModelsList[i].modelid === values.load_model) {
            modelName = ModelsList[i].modelname;
            modelClasses = JSON.stringify(ModelsList[i].classes);
            break;
          }
        }
        postData.modelname = modelName;
        postData.classes = modelClasses;

        let recordData: any = getAutoRunRecord;
        let id = recordData.id;

        /* Modify 20200208 AutoRun Use Model Classes
        if (recordData.type === "DETECT_AND_CLASSIFY" || recordData.type === "CLASSIFY") {
          let classes: any = {}
          let classifications: any = recordData.classifications;
          for (let i = 0; i < classifications.length; i++) {
            classes[classifications[i]['description']] = classifications[i]['cid']
          }
          postData.classes = JSON.stringify(classes);
          console.log(classes);
        }
        */

        //disable nms_threshold on CLASSIFY
        if (recordData.type === "CLASSIFY") {
          delete values.nms_threshold
        }

        let classes_result: any = {};
        // Loop through each class in the input array
        recordData.classifications.forEach((classItem: any) => {
          // Extract id and description from the current class item
          const { cid, description } = classItem;

          // Map 'description' to its corresponding 'id'
          if (description) {
            classes_result[description] = cid;
          }
        });

        postData.datasetclasses = JSON.stringify(classes_result);
        
        postData.username = currentUser;

        postData.params = values;
        postData.datasetname = recordData.name;

        if (values.remark.length > 0) {
          postData.remark = values.remark;
          delete values.remark;
        }

        postData.type = recordData.type;
        postData.cropRegion = recordData.cropRegion;
        postData.action = 'test';
        //postData.params.validation_size = recordData.validation_size;
        //postData.params.epochs = recordData.epochs;
        console.log(postData);

        axios.post(`${offlineUrlExpress}/api/AutoRunmodel/${id}`, JSON.stringify(postData), { headers: { 'Content-Type': 'application/json' } })
          .then(res => {
            history.push('/home/tasks')
          })

        formAutoRun.resetFields();
        setIsAutoRunModalVisible(false);
        //onCreate(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });

    //setIsAutoRunModalVisible(false);
    //history.push('/home/tasks')

  };

  const handleAutoRunCancel = () => {
    formAutoRun.resetFields();
    setIsAutoRunModalVisible(false);
  };

  const handleAutoRunReset = () => {
    formAutoRun.resetFields();
  };

  const onChangeTrainAdvanceMode = (checked: any) => {
    setTrainAdvanceMode(checked);
  }

  const onChangeAutoRunExportNB = (checked: any) => {
    setAutoRunExportNB(checked);
  }

  const onChangeAutoRunTrainNM = (checked: any) => {
    setAutoRunTrainNM(checked);
  }

  const onChangeRetrainModel = (checked: any) => {
    setretrainModel(checked);
    if (checked) {
      let recordData: any = getTrainRecord;
      axios.get(`${offlineUrlExpress}/api/getmodelslist/${recordData.type}`)
        .then(res => {
          if (res.data.length > 0) {

            let filtermodelslist: any = [];
            for (let i = 0; i < res.data.length; i++) {
              res.data[i]['classnumber'] = Object.keys(res.data[i]['classes']).length
              res.data[i]['classstring'] = Object.keys(res.data[i]['classes']).join(',')
              res.data[i]['score'] = Math.round(res.data[i]['score'] * 100) / 100;

              if (recordData.type == "DETECT") {
                filtermodelslist.push(res.data[i]);
              } else {
                if (Object.keys(res.data[i]['classes']).length == recordData.classifications.length) {
                  filtermodelslist.push(res.data[i]);
                }
              }
              /*
              res.data[i]['score'] = Math.round(res.data[i]['score'] * 100) / 100;
              if (Object.keys(res.data[i]['classes']).length == recordData.classifications.length) {
                filtermodelslist.push(res.data[i]);
              }
              */
            }

            setModelsList(filtermodelslist)
            setModelsListDefault(filtermodelslist['modelid']);

            /*
            {"epochs":20,"model_backbone":"resnet50","learning_rate":0.001,"optimizer":"adam","use_pretrained":"true","save_best_model":"false","data_aug":"false","image_size":800,"batch_size":4,"validation_size":0.2,"load_model":"false"}
            */

            formTrain.setFieldsValue({
              load_model_id: getModelsListDefault
            });

            onChangeRetrainModelSelect(getModelsListDefault);

          } else {
            setModelsList(res.data)
            setModelsListDefault("");
          }
          //console.log(res)
        })
    }
  }

  const onChangeRetrainModelSelect = (selectvalue: any) => {
    let ModelsList: any = getModelsList;

    let params: any = {};

    for (let i = 0; i < ModelsList.length; i++) {
      console.log(ModelsList[i].modelid);
      if (ModelsList[i].modelid === selectvalue) {
        params = ModelsList[i].params;
        break;
      }
    }

    /*
    {
      batch_size: 4,
      data_aug: "false",
      epochs: 20,
      image_size: 800,
      learning_rate: 0.001,
      load_model: "false",
      model_backbone: "resnet50",
      optimizer: "adam",
      save_best_model: "false",
      use_pretrained: "true",
      validation_size: 0.2
    }
    */

    if (Object.keys(params).length === 0) {
      //console.log('no params')
    } else {

      Object.keys(params).forEach(key => {
        if (params[key] === 'true') {
          params[key] = true;
        } else if (params[key] === 'false') {
          params[key] = false;
        }
      });

      formTrain.setFieldsValue(params);
    }

    //console.log(getModelsList);
    //console.log(selectvalue);
  }

  const onChangeSmallDefect = (checked: any) => {
    setSmallDefect(checked);
  }

  const onChangeValidModelSelect = (selectvalue: any) => {
    let ValidRecord: any = getValidRecord;

    if (ValidRecord['type'] === "CLASSIFY" || ValidRecord['type'] === "DETECT_AND_CLASSIFY" || ValidRecord['type'] === "SEGMENTATION" || ValidRecord['type'] === "KEYPOINT") {

      let ModelsList: any = getModelsList;
      let modelclasses: any = {};

      for (let i = 0; i < ModelsList.length; i++) {
        //console.log(ModelsList[i].modelid);
        if (ModelsList[i].modelid === selectvalue) {
          modelclasses = ModelsList[i].classes;
          break;
        }
      }

      let modelclassarray: any = Object.keys(modelclasses).map(key => ({ cid: parseInt(modelclasses[key]), description: key.toString() }))

      modelclassarray.sort(function (a: any, b: any) {
        return a.cid.toString().localeCompare(b.cid.toString());
      });

      console.log(modelclassarray)


      setValidModalClass(modelclassarray)

      let datasetclasses: any = ValidRecord['classifications'];

      datasetclasses.sort(function (a: any, b: any) {
        return a.cid.toString().localeCompare(b.cid.toString());
      });

      console.log(datasetclasses)

      setValidDatasetClass(datasetclasses)

      //console.log(getValidModalClass);
      //console.log(getValidDatasetClass);
      setMappingClassVisible(true);

      let defaultselect: any = {};
      for (let i = 0; i < ValidRecord['classifications'].length; i++) {
        defaultselect['class_' + modelclassarray[i]['cid']] = modelclassarray[i]['cid'] + "_" + datasetclasses[i]['cid']
      }


      //defaultselect = {"class_0": "0_1", "class_1": "1_2"}


      //console.log(defaultselect);

      formValid.setFieldsValue(
        defaultselect
      );
    }

  }

  const onChangeAutoRunModelSelect = (value: string[]) => {
    //console.log(`selected ${value}`);

    let ModelsList: any = getModelsList;
    let AutoRunRecord: any = getAutoRunRecord;

    let RecordClassifications = AutoRunRecord["classifications"];
    let RecordClasses: { [k: string]: any } = {};
    for (let i = 0; i < RecordClassifications.length; i++) {
      RecordClasses[RecordClassifications[i].description] = RecordClassifications[i].cid;
    }

    let modelName: string = "";
    let SelectModel;
    for (let i = 0; i < ModelsList.length; i++) {
      if (ModelsList[i].modelid === value) {
        SelectModel = ModelsList[i]
        break;
      }
    }

    if (JSON.stringify(SelectModel.classes) !== JSON.stringify(RecordClasses)) {
      message.info(`Selected Model Classes ${JSON.stringify(SelectModel.classes)} was Difference With Dataset Classes ${JSON.stringify(RecordClasses)}`);
    }
  };

  const {
    setDatasetIdForInserting,
    deleteDatasetByIdAsync,
    fetchDatasetsAsync,
    setOnGoingLabelDataset,
    toggleLabelingRefresh,
  } = useActions();

  useEffect(() => {
    if (isDeleteSuccess) {
      fetchDatasetsAsync();
    }
  }, [isDeleteSuccess, fetchDatasetsAsync]);

  useEffect(() => {
    const timer = window.setInterval(() => {
      fetchDatasetsAsync();
    }, 5000);
    return () => {
      window.clearInterval(timer);
    };
  });


  useEffect(() => {
    /*
    const newProcessIntervalId = setInterval(() => {
      axios.get(`${offlineUrlExpress}/tasks/${processTaskid}.txt`)
      .then(response => {
        setProcessStatus(response.data);
        console.log("gettaskstatus", response.data);
      })     
    }, 1000);
    setProcessIntervalId(newProcessIntervalId);
    */

    /*
    const intervalprocessing = window.setInterval(() => {

    if (processing > 0) {
    axios.get(`${offlineUrlExpress}/tasks/${processTaskid}.txt`)
      .then(response => {
        setProcessStatus(response.data);
        console.log("gettaskstatus", response.data);
      })
    }
    }, 1000);
    return () => {
      window.clearInterval(intervalprocessing)
    };    
    */
    /*
     setTimeout(() => {
       axios.get(`${offlineUrlExpress}/tasks/${processTaskid}.txt`)
       .then(response => {
         setProcessStatus(response.data);
         console.log("gettaskstatus", response.data);
       })
     }, 1000);
     */


  }, [processing]);

  useEffect(() => {
    //console.log(`!!! isOnGoingLabelModelOpen : ${isOnGoingLabelModelOpen}`);
    fetchDatasetsAsync();
  }, [isOnGoingLabelModelOpen]);

  const handleSearch = (value: any) => {
    setSearchText(value);
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 20 },
      sm: { span: 20 },
    },
  };

  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };


  const setOpenPolygonModal = (
    datasetId: number,
    datasetType: string,
    name: string,
    classifications: Classification[],
    imagesCount: number
  ) =>
    dispatchPolygon({
      type: PolygonLabelActionType.SET_POLYGON_MODAL_OPEN,
      datasetId: datasetId,
      datasetType: datasetType,
      datasetName: name,
      classifications: [{ id: null, description: 'Empty Class', cid: 0 }, ...classifications],
      imagesCount: imagesCount || 0,
    });



  return (
    <>
      {progress > -1 &&
        (<><Text>{processStatus.state} - {processStatus.message} </Text>
          <Progress strokeColor={{
            '0%': '#108ee9',
            '100%': '#87d068',
          }} percent={processStatus.percent} /></>)
      }


      <Row style={{ flex: 1, width: "100%" }} justify="end">
        <Space size="middle">

          <MyTag color="gold" onClick={(e) => { fetchDatasetsAsync(); }}>Reload</MyTag>
          <Upload {...upload_Props}>
            <MyTag color="geekblue">Import</MyTag>
          </Upload>
          <Input.Search
            //style={{ marginLeft: 420 }}
            placeholder="keywords Search..."
            onSearch={handleSearch}
            allowClear
            enterButton
          />
        </Space>
        {/*<MyTag color="gold" onClick={(e) => { cancelSource.cancel(); console.log("Cancel Task") }}>Cancel Task</MyTag>*/}
      </Row>
      <Table
        //rowKey={record => record.id}
        rowKey={"id"}
        //loading={isLoading}
        style={{ width: "100%" }}
        size="middle"
        //pagination={false}
        pagination={{ pageSize: 10 }}
        columns={columns(
          currentUser,
          autorunFunction,
          setDatasetIdForInserting,
          deleteDatasetByIdAsync,
          isDeletePending,
          setOnGoingLabelDataset,
          showModal,
          handleEditDatasetName,
          handleCopyDataset,
          handleMergeDataset,
          handleBatchDelete,
          handleImportFolderLimit,
          handleEditDatasetCropImage,
          handleExportDataset,
          uploadImage_Props,
          uploadImageZip_Props,
          setOpenPolygonModal,
        )}
        //dataSource={datasets}
        dataSource={datasets.filter(
          (item) =>
            (item.name || '').toLowerCase().includes(searchText.toLowerCase())
          //|| item.create_at.includes(searchText)
          //|| item.lastupdate.includes(searchText),
        )}
      />
      < PolygonMainDrawer />
      <Modal
        title={`Train Parameter Setting`}
        visible={isTrainModalVisible}
        onOk={handleTrainOk}
        onCancel={handleTrainCancel}
        width={1000}
        footer={[
          <Button key="trainreset" onClick={handleTrainReset}>
            Reset
          </Button>,
          <Button key="trainback" onClick={handleTrainCancel}>
            Cancel
          </Button>,
          <Button key="trainsubmit" type="primary" onClick={handleTrainOk}>
            Create Train Task
          </Button>,
        ]}
      >

        <Button type="link" block
          onClick={() => {
            Modal.info({
              title: `Instruction`,
              content: (
                <img src={traininfo} alt="traininfo" style={{ marginBottom: "1.5rem" }} />
              ),
              width: 1000,
              onOk() { },
            });
          }}
        >
          Instruction
        </Button>

        <Form form={formTrain}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 18 }}
          layout="horizontal"
          initialValues={{
            retrain_model: false, advance_mode: false, load_model_id: "", modelname: 'model_' + moment().format("YYYYMMDDhhmmss"), epochs: 20, model_backbone: 'resnet50', learning_rate: 0.0001, optimizer: 'adam', image_size: 800, batch_size: 2, validation_size: 0.2, use_pretrained: true, save_best_model: true, data_aug: true, load_model: false, source: "All",
            small_defect: false, small_defect_params: { overlap_shape: "[200,200]", clip_sizes: "[4,4]", mode: "intersect", keep_ratio: 0.8 }
          }}
        >
          <Form.Item label="Dataset Name">
            <Text> {getSelectDatasetName} </Text>
          </Form.Item>
          <Form.Item label="Retrain Model" name="retrain_model" valuePropName="checked">
            <Switch onChange={onChangeRetrainModel} />
          </Form.Item>
          {retrainModel && (
            <Form.Item label="Load Model" name="load_model_id" rules={[{ required: true, message: 'Load Model is Required' }]}>
              <Select onChange={onChangeRetrainModelSelect}>
                {
                  getModelsList.map(item => (
                    <Select.Option key={item.modelid} value={item.modelid}>
                      {`${item.modelname} , Score:${item.score} [${item.classstring}]`}
                    </Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
          )}
          <Form.Item label="Model Name" name="modelname">
            <Input placeholder="ModelName" />
          </Form.Item>
          <Form.Item label="Epochs" name="epochs">
            <InputNumber min={1} max={10000} step={1} />
          </Form.Item>

          {/* Function Remark */}
          {smalldefectFunction &&
            <Form.Item label="Small Defect" name="small_defect" valuePropName="checked">
              <Switch onChange={onChangeSmallDefect} />
            </Form.Item>
          }
          {smallDefect && (
            <Card>
              <Form.Item label="Overlap Shape" name={['small_defect_params', 'overlap_shape']}>
                <Input placeholder="Overlap Shape" />
              </Form.Item>
              <Form.Item label="Clip Sizes" name={['small_defect_params', 'clip_sizes']}>
                <Input placeholder="Clip Sizes" />
              </Form.Item>
              <Form.Item label="Mode" name={['small_defect_params', 'mode']}>
                <Radio.Group>
                  <Radio.Button value="intersect">Intersect</Radio.Button>
                  <Radio.Button value="eliminate">Eliminate</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="Keep Ratio" name={['small_defect_params', 'keep_ratio']}>
                <InputNumber min={0.1} max={1} step={0.1} />
              </Form.Item>
            </Card>
          )}

          <Form.Item label="Advance Mode" name="advance_mode" valuePropName="checked">
            <Switch onChange={onChangeTrainAdvanceMode} />
          </Form.Item>


          <div style={getTrainAdvanceMode ? {} : { display: 'none' }} >
            {!isTypeClassify && (

              <Form.Item label="Model BackBone" name="model_backbone" rules={[{ required: true, message: 'Model BackBone is Required' }]}>
                <Radio.Group>
                  <Radio.Button value="resnet18">resnet18</Radio.Button>
                  <Radio.Button value="resnet34">resnet34</Radio.Button>
                  <Radio.Button value="resnet50">resnet50</Radio.Button>
                  <Radio.Button value="resnet101">resnet101</Radio.Button>
                  <Radio.Button value="resnet152">resnet152</Radio.Button>
                  {/* Function Remark */}
                  {yoloFunction && (<>
                    <Radio.Button value="yolo-b">yolo-b</Radio.Button>
                    <Radio.Button value="yolo-x">yolo-x</Radio.Button>
                  </>
                  )
                  }
                </Radio.Group>
              </Form.Item>
            )}
            {isTypeClassify && (
              <Form.Item label="Model BackBone" name="model_backbone">
                <Radio.Group>
                  <Radio.Button value="efficientnet_b0">efficientnet_b0</Radio.Button>
                  <Radio.Button value="efficientnet_b1">efficientnet_b1</Radio.Button>
                  <Radio.Button value="efficientnet_b2">efficientnet_b2</Radio.Button>
                  <Radio.Button value="efficientnet_b3">efficientnet_b3</Radio.Button>
                  <Radio.Button value="efficientnet_b4">efficientnet_b4</Radio.Button>
                  <Radio.Button value="efficientnet_b5">efficientnet_b5</Radio.Button>
                  <Radio.Button value="efficientnet_b6">efficientnet_b6</Radio.Button>
                  <Radio.Button value="efficientnet_b7">efficientnet_b7</Radio.Button>
                  <Radio.Button value="resnest50">resnest50</Radio.Button>
                  <Radio.Button value="resnext50">resnext50</Radio.Button>
                </Radio.Group>
              </Form.Item>
            )}
            <Form.Item label="Learning Rate" name="learning_rate">
              <InputNumber min={0.000001} max={1} step={0.003} />
            </Form.Item>
            <Form.Item label="Optimizer" name="optimizer">
              <Radio.Group>
                <Radio.Button value="adam">adam</Radio.Button>
                <Radio.Button value="sgd">sgd</Radio.Button>
                <Radio.Button value="rmsprop">rmsprop</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Use Pretrained" name="use_pretrained" valuePropName="checked">
              <Switch />
            </Form.Item>
            <Form.Item label="Save Best Model" name="save_best_model" valuePropName="checked">
              <Switch />
            </Form.Item>
            <Form.Item label="Data AUG" name="data_aug" valuePropName="checked">
              <Switch />
            </Form.Item>
            <Form.Item label="Image Size" name="image_size">
              <InputNumber min={128} max={3000} step={32} />
            </Form.Item>
            <Form.Item label="Batch Size" name="batch_size">
              <InputNumber min={1} max={128} step={1} />
            </Form.Item>
            <Form.Item label="Validation Size" name="validation_size">
              <InputNumber min={0.1} max={0.9} step={0.1} />
            </Form.Item>
            <Form.Item label="Label Source Filter" name="source">
              <Radio.Group>
                <Radio.Button value="All">All Label</Radio.Button>
                <Radio.Button value="AutoRun">AutoRun Label</Radio.Button>
                <Radio.Button value="AutoLabel">Auto Label</Radio.Button>
                <Radio.Button value="Manual">Manual Label</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>

        </Form>
      </Modal>

      <Modal
        title={`Validate Parameter Setting`}
        visible={isValidModalVisible}
        onOk={handleValidOk}
        onCancel={handleValidCancel}
        width={1000}
        footer={[
          <Button key="validreset" onClick={handleValidReset}>
            Reset
          </Button>,
          <Button key="validback" onClick={handleValidCancel}>
            Cancel
          </Button>,
          <Button key="validsubmit" type="primary" onClick={handleValidOk}>
            Create Validation Task
          </Button>,

        ]}
      >
        <Button type="link" block
          onClick={() => {
            Modal.info({
              title: `Instruction`,
              content: (
                <img src={validinfo} alt="validinfo" style={{ marginBottom: "1.5rem" }} />
              ),
              width: 1000,
              onOk() { },
            });
          }}
        >
          Instruction
        </Button>
        <Form form={formValid}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          initialValues={{ load_model: "", confidence: 0.5, image_size: 800, nms_threshold: 0.01 }}
        >
          <Form.Item label="Dataset Name">
            <Text> {getSelectDatasetName} </Text>
          </Form.Item>
          <Form.Item label="Load_Model" name="load_model" rules={[{ required: true, message: 'Load Model is Required' }]}>
            <Select onChange={onChangeValidModelSelect}>
              {
                getModelsList.map(item => (
                  <Select.Option key={item.modelid} value={item.modelid}>
                    {`${item.modelname} , Score:${item.score} [${item.classstring}]`}
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item label="Image Size" name="image_size">
            <InputNumber min={128} max={3000} step={32} />
          </Form.Item>

          {!isTypeClassify && (<>
            <Form.Item label="Confidence" name="confidence">
              <InputNumber min={0} max={1} step={0.1} />
            </Form.Item>
            <Form.Item label="NMS Threshold" name="nms_threshold">
              <InputNumber min={0.01} max={0.99} step={0.01} />
            </Form.Item>
          </>)}

          {isMappingClassVisible && (
            <>
              <Title level={3}>Class Mapping</Title>
              <List
                grid={{ gutter: 16, column: 4 }}
                rowKey="key"
                dataSource={getValidModalClass}
                renderItem={item => (
                  <List.Item>
                    <Card title={item['description']}>
                      <Form.Item
                        name={`class_${item['cid']}`}
                        rules={[
                          {
                            //required: true,
                            validator: async (_, names) => {
                              console.log(names);
                              let fieldsvalue = formValid.getFieldsValue();
                              let selectvalues: any[] = [];
                              Object.keys(fieldsvalue).forEach(key => {
                                if (key.startsWith('class_')) {
                                  let selectvalue = fieldsvalue[key].split('_')[1]
                                  selectvalues.push(selectvalue);
                                  //formValid.setFields({ "class_1", errors: [] });
                                }
                              });

                              let toMap: any = {};
                              let resultToReturn = false;
                              for (let i = 0; i < selectvalues.length; i++) {

                                if (toMap[selectvalues[i]]) {
                                  resultToReturn = true;
                                  // terminate the loop
                                  break;
                                }
                                toMap[selectvalues[i]] = true;
                              }

                              if (resultToReturn) {
                                return Promise.reject(new Error('Duplicates Class'));
                              } else {
                                return true;
                              }

                              /*
                              if (!names || names.length < 2) {
                                return Promise.reject(new Error('At least 2 passengers'));
                              }
                              */
                            },
                          },
                        ]}
                      >
                        <Select style={{ width: 120 }}>
                          {getValidDatasetClass.map(sitem => (
                            <Select.Option value={`${item['cid']}_${sitem['cid']}`}>
                              {sitem['description']}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Card>
                  </List.Item>
                )}
              />
            </>
          )}

        </Form>
      </Modal>

      <Modal
        title={`Test Parameter Setting`}
        visible={isTestModalVisible}
        onOk={handleTestOk}
        onCancel={handleTestCancel}
        width={1000}
        footer={[
          <Button key="testreset" onClick={handleTestReset}>
            Reset
          </Button>,
          <Button key="testback" onClick={handleTestCancel}>
            Cancel
          </Button>,
          <Button key="Testsubmit" type="primary" onClick={handleTestOk}>
            Create Test Task
          </Button>,
        ]}
      >
        <Button type="link" block
          onClick={() => {
            Modal.info({
              title: `Instruction`,
              content: (
                <img src={testinfo} alt="testinfo" style={{ marginBottom: "1.5rem" }} />
              ),
              width: 1000,
              onOk() { },
            });
          }}
        >
          Instruction
        </Button>
        <Form form={formTest}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          initialValues={{
            load_model: "", angle: false, element_split: 1, aspect_ratio_check: false, aspect_ratio: 0.33, confidence: 0.5, image_size: 800, nms_threshold: 0.01, startrecord: 1, endrecord: 100,
            small_defect: false, small_defect_params: { overlap_shape: "[200,200]", clip_sizes: "[4,4]", nms_threshold: 0.4, nms_threshold_2: 0.6 }
          }}
        >
          <Form.Item label="Dataset Name">
            <Text> {getSelectDatasetName} </Text>
          </Form.Item>
          <Form.Item label="Load_Model" name="load_model" rules={[{ required: true, message: 'Load Model is Required' }]}>
            <Select>
              {
                getModelsList.map(item => (
                  <Select.Option key={item.modelid} value={item.modelid}>
                    {`${item.modelname} , Score:${item.score} [${item.classstring}]`}
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
          {/* Function Remark */}
          {angleFunction &&
            <Form.Item label="Angle Test" name="angle" hidden={angleFunction ? false : true}>
              <Switch onChange={setAngleTest} />
            </Form.Item>
          }
          {angleTest && (
            <Card>
              <Form.Item label="Element Split Size" name="element_split">
                <InputNumber min={1} max={100} step={1} />
              </Form.Item>
              <Form.Item label="Aspect Ratio" name="aspect_ratio_check">
                <Switch onChange={setAspectRatio} />
              </Form.Item>
              {aspectRatio &&
                <Form.Item label="Aspect Ratio Value" name="aspect_ratio">
                  <InputNumber min={0.01} max={100.00} step={0.01} />
                </Form.Item>
              }
            </Card>)}

          {/* Function Remark */}
          {smalldefectFunction &&
            <Form.Item label="Small Defect" name="small_defect" valuePropName="checked">
              <Switch onChange={onChangeSmallDefect} />
            </Form.Item>
          }
          {smallDefect && (
            <Card>
              <Form.Item label="Overlap Shape" name={['small_defect_params', 'overlap_shape']}>
                <Input placeholder="Overlap Shape" />
              </Form.Item>
              <Form.Item label="Clip Sizes" name={['small_defect_params', 'clip_sizes']}>
                <Input placeholder="Clip Sizes" />
              </Form.Item>

              <Form.Item label="NMS Threshold" name={['small_defect_params', 'nms_threshold']}>
                <InputNumber min={0.1} max={1} step={0.1} />
              </Form.Item>
              <Form.Item label="NMS Threshold2" name={['small_defect_params', 'nms_threshold_2']}>
                <InputNumber min={0.1} max={1} step={0.1} />
              </Form.Item>
            </Card>
          )}

          <Form.Item label="Image Size" name="image_size">
            <InputNumber min={128} max={3000} step={32} />
          </Form.Item>
          {!isTypeClassify && (<>
            <Form.Item label="Confidence" name="confidence">
              <InputNumber min={0} max={1} step={0.1} />
            </Form.Item>
            <Form.Item label="NMS Threshold" name="nms_threshold">
              <InputNumber min={0.01} max={0.99} step={0.01} />
            </Form.Item>
          </>)}
          <Form.Item label="Start Index" name="startrecord">
            <InputNumber min={1} />
          </Form.Item>
          <Form.Item label="End Index" name="endrecord">
            <InputNumber min={1} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={`AutoRun Parameter Setting`}
        visible={isAutoRunModalVisible}
        onOk={handleAutoRunOk}
        onCancel={handleAutoRunCancel}
        width={1000}
        footer={[
          <Button key="AutoRunreset" onClick={handleAutoRunReset}>
            Reset
          </Button>,
          <Button key="AutoRunback" onClick={handleAutoRunCancel}>
            Cancel
          </Button>,
          <Button key="AutoRunsubmit" type="primary" onClick={handleAutoRunOk}>
            Create AutoRun Task
          </Button>,
        ]}
      >
        {/*
        <Button type="link" block
          onClick={() => {
            Modal.info({
              title: `Instruction`,
              content: (
                <img src={AutoRuninfo} alt="AutoRuninfo" style={{ marginBottom: "1.5rem" }} />
              ),
              width: 1000,
              onOk() { },
            });
          }}
        >
          Instruction
        </Button>
        */}
        <Form form={formAutoRun}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          initialValues={{ load_model: "", confidence: 0.5, image_size: 800, nms_threshold: 0.01, startrecord: 1, endrecord: 100, testscore: 0.8, modelscore: 0.7, runtimecount: 3, runtime: 1, epochs: 20, validation_size: 0.2, retrain: false.valueOf, remark: '' }}
        >
          <Form.Item label="Dataset Name">
            <Text> {getSelectDatasetName} </Text>
          </Form.Item>
          <Form.Item label="Dataset Remark" name="remark">
            <Input placeholder="Dataset Remark" />
          </Form.Item>
          <Form.Item label="Load_Model" name="load_model" rules={[{ required: true, message: 'Load Model is Required' }]}>
            <Select onChange={onChangeAutoRunModelSelect}>
              {
                getModelsList.map(item => (
                  <Select.Option key={item.modelid} value={item.modelid}>
                    {`${item.modelname} , Score:${item.score} [${item.classstring}]`}
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item label="Retrain Model" name="retrain" valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item label="Image Size" name="image_size">
            <InputNumber min={128} max={3000} step={32} />
          </Form.Item>
          {!isTypeClassify && (<>
            <Form.Item label="Confidence" name="confidence">
              <InputNumber min={0} max={1} step={0.1} />
            </Form.Item>
            <Form.Item label="NMS Threshold" name="nms_threshold">
              <InputNumber min={0.01} max={0.99} step={0.01} />
            </Form.Item>
          </>)}
          <Form.Item label="Start Index" name="startrecord">
            <InputNumber min={1} />
          </Form.Item>
          <Form.Item label="End Index" name="endrecord">
            <InputNumber min={1} />
          </Form.Item>
          <Form.Item label="Test Score Import Restrictions" name="testscore">
            <InputNumber min={0.1} max={1} step={0.1} />
          </Form.Item>
          <Form.Item label="Run Time Restrictions" name="runtimecount">
            <InputNumber min={1} max={10} step={1} />
          </Form.Item>
          <Form.Item label="Model Score Restrictions" name="modelscore">
            <InputNumber min={0.1} max={1} step={0.1} />
          </Form.Item>
          <Form.Item label="Epochs [Train]" name="epochs">
            <InputNumber min={1} max={999} step={1} />
          </Form.Item>
          <Form.Item label="Validation Size [Train]" name="validation_size">
            <InputNumber min={0.1} max={0.9} step={0.1} />
          </Form.Item>
          {/*        
         <Form.Item label="Export to New Dataset" name="option_AutoRuntonewdatasets" valuePropName="checked">
            <Switch onChange={onChangeAutoRunExportNB} />
          </Form.Item>
          {getAutoRunExportNB && (
            <Form.Item label="New Dataset Name" name="AutoRuntonewdatasets">
            <Input placeholder="NewDatasetName" />
            </Form.Item>
          )}
          <Form.Item label="Train New Model" name="option_AutoRuntraintonewmodel" valuePropName="checked">
            <Switch onChange={onChangeAutoRunTrainNM} />
          </Form.Item>
          {getAutoRunTrainNM && (
            <Form.Item label="New Model Name" name="AutoRuntraintonewmodel">
            <Input placeholder="NewModelName" />
            </Form.Item>
          )}
          */}
        </Form>
      </Modal>

      <Modal
        title={`Edit Dataset Name`}
        visible={isEditDatasetNameVisible}
        onOk={handleEditDatasetNameDatasetNameOk}
        onCancel={handleEditDatasetNameDatasetNameCancel}
        width={1000}
      >
        <Form form={formEditDatasetName}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          initialValues={{ datasetname: "" }}
        >
          <Form.Item label="Dataset Name">
            <Text> {getSelectDatasetName} </Text>
          </Form.Item>
          <Form.Item label="New Dataset Name" name="datasetname" rules={[{ required: true, message: 'Dataset Name is Required' }]}>
            <Input placeholder="New Dataset Name" />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={`Duplicate Dataset`}
        visible={isDuplicateDatasetVisible}
        onOk={handleDuplicateDatasetOk}
        onCancel={handleDuplicateDatasetCancel}
        width={1000}
        footer={[
          <Button key="duplicatereset" onClick={handleDuplicateDatasetPreview}>
            Preview File List
          </Button>,
          <Button key="duplicateback" onClick={handleDuplicateDatasetCancel}>
            Cancel
          </Button>,
          <Button key="duplicatesubmit" type="primary" onClick={handleDuplicateDatasetOk}>
            Duplicate Datasets
          </Button>,

        ]}
      >
        <Form form={formDuplicateDataset}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          initialValues={{ datasetname: "", datasettype: "", changedatatype: false, filter: [], changeclassificationfilter: false, classificationfilter: [] }}
        >
          <Form.Item label="Dataset Name">
            <Text> {getSelectDatasetName} </Text>
          </Form.Item>
          <Form.Item label="New Dataset Name" name="datasetname" rules={[{ required: true, message: 'Dataset Name is Required' }]}>
            <Input placeholder="New Dataset Name" />
          </Form.Item>
          {!isTypeClassify &&
            <Form.Item label="Change Dataset Type" name="changedatatype" valuePropName="checked">
              <Switch onChange={onChangeDataType} />
            </Form.Item>
          }
          {IsChangeDataType && (<>
            <Form.Item label="New Dataset Type" name="datasettype" rules={[{ required: true, message: 'Dataset Type is Required' }]}>
              <Radio.Group>
                <Radio.Button value="DETECT">DETECT</Radio.Button>
                <Radio.Button value="DETECT_AND_CLASSIFY">DETECT_AND_CLASSIFY</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </>)}

          <Form.Item label="Classification Filter" name="changeclassificationfilter" valuePropName="checked">
            <Switch onChange={onChangeClassificationFilter} />
          </Form.Item>

          {IsChangeClassificationFilter && (<>
            <Form.Item label="Classification Select" name="classificationfilter">
              <Select onChange={onChangeClassificationFilterSelect} mode="multiple" allowClear style={{ width: '100%' }}>
                {
                  getCopyDatasetClassifications.map(item => (
                    <Select.Option key={item['id']} value={item['description']}>
                      {item['description']}
                    </Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
          </>)}

          <Form.List
            name="filter"
          /*
          rules={[
            {
              validator: async (_, names) => {
                if (!names || names.length < 2) {
                  return Promise.reject(new Error('At least 2 passengers'));
                }
              },
            },
          ]}
          */
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    label={index === 0 ? 'Keyword Filter' : ''}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please input keyword filter or delete this field.",
                        },
                      ]}
                      noStyle
                    >
                      <Input placeholder="Keyword Filter" style={{ width: '60%' }} />
                    </Form.Item>
                    <MinusCircleOutlined
                      style={{ fontSize: "1.5rem", marginLeft: "5px" }}
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '60%' }}
                    icon={<PlusOutlined />}
                  >
                    Add Keyword Filter
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>

        </Form>
      </Modal>

      <Modal
        title={`Merge Dataset`}
        visible={isMergeDatasetVisible}
        onOk={handleMergeDatasetOk}
        onCancel={handleMergeDatasetCancel}
        width={1000}
        footer={[
          /*
          <Button key="Mergereset" onClick={handleMergeDatasetPreview}>
            Preview File List
          </Button>,
          */
          <Button key="Mergeback" onClick={handleMergeDatasetCancel}>
            Cancel
          </Button>,
          <Button key="Mergesubmit" type="primary" onClick={handleMergeDatasetOk}>
            Merge Datasets
          </Button>,

        ]}
      >
        <Form form={formMergeDataset}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          initialValues={{ datasetname: "", mergedatasetid: null }}
        >
          <Form.Item label="Dataset Name">
            <Text> {getSelectDatasetName} </Text>
          </Form.Item>
          <Form.Item label="New Dataset Name" name="datasetname" rules={[{ required: true, message: 'Dataset Name is Required' }]}>
            <Input placeholder="New Dataset Name" />
          </Form.Item>

          <Form.Item label="Merge Dataset" name="merge_dataset_id" rules={[{ required: true, message: 'Merge Dataset is Required' }]}>
            <Select>
              {
                datasets.filter(element => element.type === getSelectRecord['type'] && element.id !== getSelectRecord['id']).map(item => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name} [ {item.hasLabeled} / {item.imagesCount} ]
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>

        </Form>
      </Modal>


      <Modal
        title={`Batch Delete`}
        visible={isBatchDeleteVisible}
        onOk={handleBatchDeleteOk}
        onCancel={handleBatchDeleteCancel}
        width={1000}
        footer={[
          <Button key="batchdeletepreview" onClick={handleBatchDeletePreview}>
            Preview File List
          </Button>,
          <Button key="batchdeleteback" onClick={handleBatchDeleteCancel}>
            Cancel
          </Button>,
          <Popconfirm key="batchdeletepopconfirm" title="Are you sure？" okText="Yes" cancelText="No" onConfirm={handleBatchDeleteOk}>
            <Button key="batchdeletesubmit" type="primary" >
              Batch Delete
            </Button>
          </Popconfirm>,

        ]}
      >
        <Form form={formBatchDelete}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          initialValues={{ filter: [], startrecord: 1, endrecord: 100, indexrange: "" }}
        >
          <Form.Item label="Dataset Name">
            <Text> {getSelectDatasetName} </Text>
          </Form.Item>

          <Form.List
            name="filter"
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    label={index === 0 ? 'Keyword Filter' : ''}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please input keyword filter or delete this field.",
                        },
                      ]}
                      noStyle
                    >
                      <Input placeholder="Keyword Filter" style={{ width: '60%' }} />
                    </Form.Item>
                    <MinusCircleOutlined
                      style={{ fontSize: "1.5rem", marginLeft: "5px" }}
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '60%' }}
                    icon={<PlusOutlined />}
                  >
                    Add Keyword Filter
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item label="Index Range">
            <Form.Item name="indexrange" noStyle
              rules={[
                {
                  required: true,
                  message: 'Index Range can not be null',
                },
                {
                  pattern: new RegExp(/^[0-9,-]*$/),
                  message: "Index Range Format Error, Must be include ( 0-9 , - )"
                }
              ]
              }
            >
              <Input placeholder="Example : 1-5, 8, 20-30" />
            </Form.Item>
            <span className="ant-form-text"> Example : 1-5, 8, 20-30</span>
          </Form.Item>
          {/*
          <Form.Item label="Start Index" name="startrecord">
            <InputNumber min={1} />
          </Form.Item>
          <Form.Item label="End Index" name="endrecord">
            <InputNumber min={1} />
          </Form.Item>
            */}
          <Form.Item label="Min Date" name="minDate">
            <DatePicker showTime />
          </Form.Item>
          <Form.Item label="Max Date" style={{ margin: 0 }} name="maxDate">
            <DatePicker showTime />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={`Dataset Image Crop`}
        visible={isDatasetCropImageVisible}
        onOk={handleDatasetCropImageDatasetNameOk}
        onCancel={handleDatasetCropImageDatasetNameCancel}
        width={1200}
        footer={[
          <Button key="back" onClick={handleDatasetCropImageDatasetNameCancel}>
            cancel
          </Button>,
          <Button key="save" onClick={handleDatasetCropImageDatasetNameOk}>
            Save
          </Button>,
        ]}
      >

        <DatasetImageCrop datasetData={datasetForCrop} setDatasetPosition={setDatasetPosition} />

      </Modal>

      <Modal
        title={`Import Folder Limit Setting`}
        visible={isImportFolderLimitVisible}
        onOk={handleImportFolderLimitOk}
        onCancel={handleImportFolderLimitCancel}
        width={1000}
        footer={[
          <Button key="batchdeleteback" onClick={handleImportFolderLimitCancel}>
            Cancel
          </Button>,
          <Button key="importfolderlimitsubmit" type="primary" onClick={handleImportFolderLimitOk}>
            Change Import Folder Limit
          </Button>
        ]}
      >
        <Form form={formImportFolderLimit}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          initialValues={{ importlimit: 1000 }}
        >
          <Form.Item label="Dataset Name">
            <Text> {getSelectDatasetName} </Text>
          </Form.Item>

          <Form.Item label="Import Folder Limit" name="importlimit">
            <InputNumber min={1} />
          </Form.Item>
        </Form>
      </Modal>


    </>
  );
};

export default DatasetsTable;

