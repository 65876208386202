import { CaretLeftOutlined, CaretRightOutlined, GatewayOutlined, InfoCircleOutlined, LockOutlined, MinusCircleFilled, UnlockOutlined } from "@ant-design/icons";
import { Button, message, Popover, Select } from "antd";
import { useEffect, useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { saveDetectLabels } from "../../contexts/polygonAnyCreator";
import { PolygonLabelActionType } from '../../contexts/polygonLabelAction';
import { usePolygonContext } from "../../contexts/polygonLabelContext";
import PolygonClassPicker from "../Polygon-Class-Picker";
import { colorType, MainOption, OptionType } from "../Polygon-Main-Drawer";



const PolygonController = ({
    globalOption,
    option,
    handleMainOption,
    handleGlobalOption
}: {
    globalOption: OptionType,
    option: MainOption,
    handleGlobalOption: (option: OptionType) => void;
    handleMainOption: (key: string, value: string | boolean | number) => void
}) => {

    const { polygonState, dispatchPolygon } = usePolygonContext();
    const { imagesCount, currentIndex, classSummary, classifications } = polygonState;
    const { polygonRects, selectRect, } = polygonState;

    const saveRef = useRef<HTMLButtonElement | null>(null);
    const preRef = useRef<HTMLButtonElement | null>(null);
    const nextRef = useRef<HTMLButtonElement | null>(null);
    const lockRef = useRef<HTMLButtonElement | null>(null);
    const drawRef = useRef<HTMLButtonElement | null>(null);

    const handleGuideToIndex = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (option.locked) return;
        const index = parseInt(e.target.value);
        if (index <= imagesCount - 1) {
            dispatchPolygon({
                type: PolygonLabelActionType.SET_POLYGON_NAVIGATE_TO_INDEX,
                index: index - 1
            });
        }
    };

    const handleGuideNextAndPre = (direction: 'next' | 'pre') => {
        if (option.locked) return
        if (direction === 'next' && (currentIndex + 1) < imagesCount) {
            dispatchPolygon({
                type: PolygonLabelActionType.SET_POLYGON_NAVIGATE_TO_INDEX,
                index: currentIndex + 1
            });
        }
        if (direction === 'pre' && (currentIndex - 1) >= 0) {
            dispatchPolygon({
                type: PolygonLabelActionType.SET_POLYGON_NAVIGATE_TO_INDEX,
                index: currentIndex - 1
            });
        }
    };



    const handleSave = async () => {
        try {
            const res = await saveDetectLabels(polygonState.currentImage!.id, polygonRects);
            if (res.success) {
                message.success(res.message)
            } else {
                throw new Error(res.message)
            }
        } catch (err: any) {
            message.error(err.message)
        }

    };

    useEffect(() => {
        if (selectRect && option.onDraw) {
            handleMainOption('onDraw', false)
        }
    }, [selectRect]);


    useHotkeys('s', (): any => {
        if (saveRef && saveRef.current) {
            saveRef.current.click();
        }
    });
    useHotkeys('a', (): any => {
        if (preRef && preRef.current) {
            preRef.current.click();
        }
    });
    useHotkeys('d', (): any => {
        if (nextRef && nextRef.current) {
            nextRef.current.click();
        }
    });
    useHotkeys('q', (): any => {
        if (lockRef && lockRef.current) {
            lockRef.current.click();
        }
    });
    useHotkeys('e', (): any => {
        if (drawRef && drawRef.current && option.onDraw) {
            drawRef.current.click();
        }
    });
    useHotkeys('r', (): any => {
        if (drawRef && drawRef.current) {
            drawRef.current.click();
        }
    });


    return (
        <div style={{
            position: 'relative',
            textAlign: 'center',
            width: 200, border: '0px solid', height: '80vh', borderRadius: 10,
            boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
        }}>
            <div style={{ textAlign: 'center', paddingTop: 20, display: 'flex', justifyContent: 'center', gap: 10 }}>
                <h2>Controller</h2>
                <Popover placement="bottom" title='Hot Key Panel'
                    content={<div >
                        <section>Prev : [ A ]</section>
                        <section>Next : [ D ]</section>
                        <section>Lock : [ Q ]</section>
                        <section>Draw : [ R ]</section>
                        <section>Save : [ S ]</section>
                    </div>}>
                    <Button type='text' icon={<InfoCircleOutlined />} />
                </Popover>
            </div>
            <div style={{ textAlign: 'center' }}>
                <h3>Current Index [ {currentIndex + 1}/{imagesCount}]</h3>
                <input
                    type="number"
                    value={currentIndex + 1}
                    min={1}
                    max={imagesCount}
                    onChange={handleGuideToIndex}
                    style={{ width: '60%', textAlign: 'center', borderRadius: 5 }}
                />
            </div>
            <div>
                <br />
                <div style={{ display: 'flex', justifyContent: 'center', gap: 10 }}>
                    <Button
                        ref={preRef}
                        icon={<CaretLeftOutlined />} onClick={() => handleGuideNextAndPre('pre')} />
                    <Button
                        ref={lockRef}
                        danger={option.locked}
                        icon={option.locked ? <LockOutlined /> : <UnlockOutlined />}
                        onClick={() => handleMainOption('locked', !option.locked)} />
                    <Button
                        ref={nextRef}
                        icon={<CaretRightOutlined />} onClick={() => handleGuideNextAndPre('next')} />
                </div>
            </div>
            <div style={{ textAlign: 'center' }}>
                <br />
                <h3>Draw</h3>
                <Button
                    ref={drawRef}
                    danger={option.onDraw}
                    icon={<GatewayOutlined style={{ fontSize: 28 }} />}
                    style={{ width: 52, height: 52 }}
                    onClick={() => handleMainOption('onDraw', !option.onDraw)}
                />
            </div>
            <div>
                <br />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 6 }}>
                    {/* <Tooltip placement="right" title={"CLEAR"}>
                        <Button icon={<ClearOutlined />} onClick={() => dispatchPolygon({ type: PolygonLabelActionType.SET_POLYGON_ELEMENT_CLEAR })} />
                    </Tooltip> */}
                </div>
            </div>
            <div>
                <br />
                <h3>Line Color</h3>
                <div style={{ marginTop: 5, display: 'flex', justifyContent: 'center', gap: 5 }}>
                    {Object.entries(colorType).map(([key, value]) =>
                        <Button
                            onClick={() => handleMainOption('rectColor', value)}
                            key={key} shape="circle" size='small' icon={<MinusCircleFilled style={{ color: value }} />} />
                    )}

                </div>
            </div>
            <div>
                <br />
                <h3>Classification</h3>
                <div style={{ padding: 10 }}>
                    <Select
                        size='small'
                        // labelInValue={true}
                        onSelect={(value) => handleMainOption('defaultClassification', value)}
                        style={{ width: '80%' }}
                        value={option.defaultClassification}
                        options={[...classifications].map(item => {
                            return {
                                label: item.description,
                                value: item.id
                            }
                        })}
                    />
                </div>

            </div>
            <div style={{ textAlign: 'center' }}>
                <br />
                <h3>Class Summary</h3>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 6, alignItems: 'center' }}>
                    {classSummary.map(item =>
                        <PolygonClassPicker
                            key={item.class}
                            classItem={item}
                            onClass={globalOption.classed === item.classlabel}
                            onClick={() => handleGlobalOption({
                                classed: item.classlabel,
                                filterSource: 'All',
                                isImport: undefined,
                                filterLabeled: undefined
                            })} />
                    )}
                </div>
            </div>

            <div style={{ position: 'absolute', bottom: 10, width: '100%' }}>
                <Button ref={saveRef} style={{ width: '90%' }} onClick={() => handleSave()}>
                    Save
                </Button>
            </div>
        </div>
    )
}

export default PolygonController


// setFilterLabeled(undefined);
// setIsImport(undefined);
// setFilterSource("All");
// setClassed(item.classlabel);
// datasetImageNavigateTo(0);
